var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-10" },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            {
              staticClass: "text-right d-flex justify-end",
              attrs: { cols: "10" }
            },
            [
              _c("expire-hits-button", {
                attrs: { duct: _vm.duct, hids: _vm.selectedHITIds }
              }),
              _c("delete-hits-button", {
                attrs: { duct: _vm.duct, hids: _vm.selectedHITIds }
              }),
              _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: {
                    dark: "",
                    color: "indigo",
                    to: "/console/platform/mturk/hit/create/"
                  }
                },
                [_vm._v(" Create HITs... ")]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _c(
                "v-card",
                { attrs: { cols: "10" } },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      dense: "",
                      "show-select": "",
                      "show-expand": "",
                      "sort-desc": "",
                      headers: _vm.headers,
                      items: _vm.hitTypes,
                      "single-expand": false,
                      expanded: _vm.expanded,
                      "item-key": "id",
                      loading: _vm.loading,
                      "sort-by": "creation_time"
                    },
                    on: {
                      "update:expanded": function($event) {
                        _vm.expanded = $event
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "top",
                        fn: function() {
                          return [
                            _c(
                              "v-card-title",
                              [
                                _vm._v(" HITs "),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.listHITs(false)
                                      }
                                    }
                                  },
                                  [_c("v-icon", [_vm._v("mdi-refresh")])],
                                  1
                                ),
                                _c("v-spacer"),
                                _c("v-text-field", {
                                  attrs: {
                                    "single-line": "",
                                    "hide-details": "",
                                    "append-icon": "mdi-magnify",
                                    label: "Search"
                                  },
                                  model: {
                                    value: _vm.searchQuery,
                                    callback: function($$v) {
                                      _vm.searchQuery = $$v
                                    },
                                    expression: "searchQuery"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "item.active",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            item.detail.HITStatusCount.Assignable > 0
                              ? _c("v-icon", { attrs: { color: "success" } }, [
                                  _vm._v("mdi-circle-medium")
                                ])
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "item.id",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "text-truncate",
                                staticStyle: { "max-width": "100px" }
                              },
                              [_vm._v(_vm._s(item.id))]
                            ),
                            item.detail.HITStatusCount.Assignable > 0
                              ? _c(
                                  "v-icon",
                                  {
                                    attrs: { small: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.openNewHITWindow(
                                          item.groupId,
                                          _vm.credentials.Sandbox
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" mdi-open-in-new ")]
                                )
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "item.title",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "text-truncate",
                                staticStyle: { "max-width": "100px" }
                              },
                              [_vm._v(" " + _vm._s(item.title) + " ")]
                            )
                          ]
                        }
                      },
                      {
                        key: "item.project_names",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            item.project_names && item.project_names.length == 1
                              ? _c("div", [
                                  _vm._v(
                                    " " + _vm._s(item.project_names[0]) + " "
                                  )
                                ])
                              : _vm._e(),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          item.project_names &&
                                          item.project_names.length > 1
                                            ? _c(
                                                "div",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticStyle: {
                                                        "font-weight": "bold",
                                                        color: "red"
                                                      },
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          item.project_names.join(
                                                            ",<br>"
                                                          )
                                                        )
                                                      }
                                                    },
                                                    "div",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "span",
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "white" } },
                                      [_vm._v("mdi-alert")]
                                    ),
                                    _vm._v(
                                      " Multiple projects are bound to one HIT Type"
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "item.num_hits",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _vm._v(
                              " " +
                                _vm._s(item.num_hits) +
                                " ( " +
                                _vm._s(item.num_assignable) +
                                " / " +
                                _vm._s(item.num_reviewable) +
                                " ) "
                            )
                          ]
                        }
                      },
                      {
                        key: "item.reward",
                        fn: function(ref) {
                          var item = ref.item
                          return [_vm._v(" $" + _vm._s(item.reward) + " ")]
                        }
                      },
                      {
                        key: "item.refresh",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            item.refresh == false
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: { icon: "" },
                                    on: {
                                      click: function($event) {
                                        item.refresh = true
                                        _vm.listHITs(item.id, false)
                                      }
                                    }
                                  },
                                  [_c("v-icon", [_vm._v("mdi-refresh")])],
                                  1
                                )
                              : _c("v-progress-circular", {
                                  attrs: {
                                    indeterminate: "",
                                    color: "grey darken-2",
                                    size: 20,
                                    width: 2
                                  }
                                })
                          ]
                        }
                      },
                      {
                        key: "expanded-item",
                        fn: function(ref) {
                          var headers = ref.headers
                          var item = ref.item
                          return [
                            _c("td", { attrs: { colspan: headers.length } }, [
                              _c(
                                "div",
                                { staticClass: "my-2" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex flex-row-reverse" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            icon: "",
                                            color: "grey lighten-1"
                                          }
                                        },
                                        [_c("v-icon", [_vm._v("mdi-pencil")])],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            icon: "",
                                            color: "grey lighten-1"
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-account-edit")
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" Keywords: "),
                                  _c("b", [
                                    _vm._v(_vm._s(item.detail.Props.Keywords))
                                  ]),
                                  _c("br"),
                                  _vm._v(" Description: "),
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(item.detail.Props.Description)
                                    )
                                  ]),
                                  _c("br"),
                                  _vm._v(" Auto-approval delay: "),
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.secondsToTimeString(
                                          item.detail.Props
                                            .AutoApprovalDelayInSeconds
                                        )
                                      )
                                    )
                                  ]),
                                  _c("br"),
                                  _vm._v(" Assignment duration: "),
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.secondsToTimeString(
                                          item.detail.Props
                                            .AssignmentDurationInSeconds
                                        )
                                      )
                                    )
                                  ]),
                                  _c("br"),
                                  _vm._v(" Raw data: "),
                                  _c("vue-json-pretty", {
                                    staticStyle: { "font-size": "0.6em" },
                                    attrs: { data: item.detail, deep: 1 }
                                  })
                                ],
                                1
                              )
                            ])
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.selectedHITTypes,
                      callback: function($$v) {
                        _vm.selectedHITTypes = $$v
                      },
                      expression: "selectedHITTypes"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }