var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.hids.length > 0
        ? _c(
            "v-btn",
            {
              staticClass: "mx-2",
              attrs: { dark: "", loading: _vm.loading, color: "error" },
              on: {
                click: function($event) {
                  return _vm.deleteHITs()
                }
              }
            },
            [_vm._v(" Delete (" + _vm._s(_vm.hids.length) + ") ")]
          )
        : _vm._e(),
      _c("tutti-snackbar", { ref: "snackbar" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }